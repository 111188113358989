export const kabupatens = [
    { "label": "SUMATERA SELATAN", "value": "SUMATERA SELATAN" },
    { "label": "BANYUASIN", "value": "BANYUASIN" },
    { "label": "EMPAT LAWANG", "value": "EMPAT LAWANG" },
    { "label": "LAHAT", "value": "LAHAT" },
    { "label": "MUARA ENIM", "value": "MUARA ENIM" },
    { "label": "MUSI BANYUASIN", "value": "MUSI BANYUASIN" },
    { "label": "MUSI RAWAS", "value": "MUSI RAWAS" },
    { "label": "MUSI RAWAS UTARA", "value": "MUSI RAWAS UTARA" },
    { "label": "OGAN ILIR", "value": "OGAN ILIR" },
    { "label": "OGAN KOMERING ILIR", "value": "OGAN KOMERING ILIR" },
    { "label": "OGAN KOMERING ULU", "value": "OGAN KOMERING ULU" },
    { "label": "OGAN KOMERING ULU SELATAN", "value": "OGAN KOMERING ULU SELATAN" },
    { "label": "OGAN KOMERING ULU TIMUR", "value": "OGAN KOMERING ULU TIMUR" },
    { "label": "PENUKAL ABAB LEMATANG ILIR", "value": "PENUKAL ABAB LEMATANG ILIR" },
    { "label": "LUBUKLINGGAU", "value": "LUBUKLINGGAU" },
    { "label": "PAGAR ALAM", "value": "PAGAR ALAM" },
    { "label": "PALEMBANG", "value": "PALEMBANG" },
    { "label": "PRABUMULIH", "value": "PRABUMULIH" },
    { "label": "LAINNYA", "value": "LAINNYA" },
]

export const opds = [
    { "label": "DINAS PENDIDIKAN", "value": "DINAS PENDIDIKAN" },
    { "label": "DINAS KESEHATAN", "value": "DINAS KESEHATAN" },
    { "label": "DINAS PEKERJAAN UMUM DAN PENATAAN RUANG", "value": "DINAS PEKERJAAN UMUM DAN PENATAAN RUANG" },
    { "label": "DINAS PERHUBUNGAN", "value": "DINAS PERHUBUNGAN" },
    { "label": "DINAS SOSIAL", "value": "DINAS SOSIAL" },
    { "label": "DINAS PERTANIAN", "value": "DINAS PERTANIAN" },
    { "label": "DINAS KETAHANAN PANGAN", "value": "DINAS KETAHANAN PANGAN" },
    { "label": "DINAS LINGKUNGAN HIDUP", "value": "DINAS LINGKUNGAN HIDUP" },
    { "label": "DINAS PARIWISATA", "value": "DINAS PARIWISATA" },
    { "label": "DINAS PERDAGANGAN", "value": "DINAS PERDAGANGAN" },
    { "label": "DINAS PERINDUSTRIAN", "value": "DINAS PERINDUSTRIAN" },
    { "label": "DINAS KOMUNIKASI DAN INFORMATIKA", "value": "DINAS KOMUNIKASI DAN INFORMATIKA" },
    { "label": "DINAS KEPENDUDUKAN DAN PENCATATAN SIPIL", "value": "DINAS KEPENDUDUKAN DAN PENCATATAN SIPIL" },
    { "label": "DINAS PEMUDA DAN OLAHRAGA", "value": "DINAS PEMUDA DAN OLAHRAGA" },
    { "label": "DINAS PERPUSTAKAAN DAN KEARSIPAN", "value": "DINAS PERPUSTAKAAN DAN KEARSIPAN" },
    { "label": "DINAS PEMBERDAYAAN PEREMPUAN DAN PERLINDUNGAN ANAK", "value": "DINAS PEMBERDAYAAN PEREMPUAN DAN PERLINDUNGAN ANAK" },
    { "label": "DINAS PEMBERDAYAAN MASYARAKAT DAN DESA", "value": "DINAS PEMBERDAYAAN MASYARAKAT DAN DESA" },
    { "label": "DINAS KOPERASI DAN UMKM", "value": "DINAS KOPERASI DAN UMKM" },
    { "label": "BADAN PERENCANAAN PEMBANGUNAN DAERAH", "value": "BADAN PERENCANAAN PEMBANGUNAN DAERAH" },
    { "label": "BADAN PENGELOLAAN KEUANGAN DAERAH", "value": "BADAN PENGELOLAAN KEUANGAN DAERAH" },
    { "label": "BADAN KEPEGAWAIAN DAERAH", "value": "BADAN KEPEGAWAIAN DAERAH" },
    { "label": "BADAN PENGAWASAN PEMBANGUNAN DAERAH", "value": "BADAN PENGAWASAN PEMBANGUNAN DAERAH" },
    { "label": "BADAN PENDAPATAN DAERAH", "value": "BADAN PENDAPATAN DAERAH" },
    { "label": "SEKRETARIAT DAERAH", "value": "SEKRETARIAT DAERAH" },
    { "label": "SEKRETARIAT DPRD", "value": "SEKRETARIAT DPRD" },
    { "label": "INSPEKTORAT DAERAH", "value": "INSPEKTORAT DAERAH" },
    { "label": "LAINNYA", "value": "LAINNYA" },
]

export const sdgs = [
    { "label": "TANPA KEMISKINAN", "value": "TANPA KEMISKINAN" },
    { "label": "TANPA KELAPARAN", "value": "TANPA KELAPARAN" },
    { "label": "KEHIDUPAN SEHAT DAN SEJAHTERA", "value": "KEHIDUPAN SEHAT DAN SEJAHTERA" },
    { "label": "PENDIDIKAN BERKUALITAS", "value": "PENDIDIKAN BERKUALITAS" },
    { "label": "KESETARAAN GENDER", "value": "KESETARAAN GENDER" },
    { "label": "AIR BERSIH DAN SANITASI LAYAK", "value": "AIR BERSIH DAN SANITASI LAYAK" },
    { "label": "ENERGI BERSIH DAN TERJANGKAU", "value": "ENERGI BERSIH DAN TERJANGKAU" },
    { "label": "PEKERJAAN LAYAK DAN PERTUMBUHAN EKONOMI", "value": "PEKERJAAN LAYAK DAN PERTUMBUHAN EKONOMI" },
    { "label": "INDUSTRI, INOVASI, DAN INFRASTRUKTUR", "value": "INDUSTRI, INOVASI, DAN INFRASTRUKTUR" },
    { "label": "BERKURANGNYA KESENJANGAN", "value": "BERKURANGNYA KESENJANGAN" },
    { "label": "KOTA DAN PEMUKIMAN YANG BERKELANJUTAN", "value": "KOTA DAN PEMUKIMAN YANG BERKELANJUTAN" },
    { "label": "KONSUMSI DAN PRODUKSI YANG BERTANGGUNG JAWAB", "value": "KONSUMSI DAN PRODUKSI YANG BERTANGGUNG JAWAB" },
    { "label": "PENANGANAN PERUBAHAN IKLIM", "value": "PENANGANAN PERUBAHAN IKLIM" },
    { "label": "EKOSISTEM LAUTAN", "value": "EKOSISTEM LAUTAN" },
    { "label": "EKOSISTEM DARATAN", "value": "EKOSISTEM DARATAN" },
    { "label": "PERDAMAIAN, KEADILAN, DAN KELEMBAGAAN YANG TANGGUH", "value": "PERDAMAIAN, KEADILAN, DAN KELEMBAGAAN YANG TANGGUH" },
    { "label": "KEMITRAAN UNTUK MENCAPAI TUJUAN", "value": "KEMITRAAN UNTUK MENCAPAI TUJUAN" },
    { "label": "LAINNYA", "value": "LAINNYA" },
]

export const categories = [
    { "label": "PENDIDIKAN", "value": "PENDIDIKAN", "color": "#FF5733" }, // Example color
    { "label": "KESEHATAN", "value": "KESEHATAN", "color": "#33FF57" },   // Example color
    { "label": "KETAHANAN PANGAN", "value": "KETAHANAN PANGAN", "color": "#3357FF" },
    { "label": "FASILITAS UMUM", "value": "FASILITAS UMUM", "color": "#FFC300" },
    { "label": "LINGKUNGAN", "value": "LINGKUNGAN", "color": "#DAF7A6" },
    { "label": "PEMBERDAYAAN EKONOMI", "value": "PEMBERDAYAAN EKONOMI", "color": "#FF33FF" },
    { "label": "INFRASTRUKTUR", "value": "INFRASTRUKTUR", "color": "#900C3F" },
    { "label": "SOSIAL DAN BUDAYA", "value": "SOSIAL DAN BUDAYA", "color": "#C70039" },
    { "label": "PEMBERDAYAAN MASYARAKAT", "value": "PEMBERDAYAAN MASYARAKAT", "color": "#581845" },
    { "label": "KESELAMATAN DAN KESEJAHTERAAN", "value": "KESELAMATAN DAN KESEJAHTERAAN", "color": "#FFC0CB" },
    { "label": "KEMISKINAN", "value": "KEMISKINAN", "color": "#00FFFF" },
    { "label": "BANTUAN UMKM", "value": "BANTUAN UMKM", "color": "#FFD700" },
    { "label": "LAINNYA", "value": "LAINNYA", "color": "#808080" }
];